<!--
 * @Description: 添加关联任务单弹框
 * @Author: ChenXueLin
 * @Date: 2021-10-19 14:09:48
 * @LastEditTime: 2022-07-01 09:27:27
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="关联工单"
    :visible.sync="addOrderDialog"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
    v-loading="loading"
  >
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="itemIdList">
        <input-plus
          isNumber
          v-model="searchForm.itemIdList"
          valueType="Array"
          placeholder="工单ID"
          title="工单ID"
          label="工单ID"
          clearable
        ></input-plus>
      </el-form-item>
      <!-- <el-form-item class="search-item--1" prop="taskSourceNo">
        <el-input
          v-model="searchForm.taskSourceNo"
          placeholder="来源单号"
          title="来源单号"
        ></el-input>
      </el-form-item> -->
      <el-form-item class="search-item--1" prop="taskNumber">
        <el-input
          v-model="searchForm.taskNumber"
          placeholder="任务单号"
          title="任务单号"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="corpName">
        <input-select
          v-model="searchForm.corpName"
          :data="corpList"
          placeholder="客户名称"
          title="客户名称"
          clearable
          virtual
          remote
          :is-title="true"
          @filterChange="handleLoadCorpFilter"
          @clear="handleClear"
          :props="{
            id: 'corpId',
            label: 'corpName'
          }"
        ></input-select>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      height="300px"
      :data="tableData"
      highlight-current-row
      ref="elTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { bindTaskColumnData } from "../columnData";
import inputPlus from "@/components/inputPlus";
import { createProjectTaskRel, getWorkCorpList, getItemListPage } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "",
  components: { inputPlus },
  props: ["addOrderDialog", "orderDialogType", "projectId"],
  data() {
    return {
      corpList: [],
      columnData: bindTaskColumnData, //表头
      searchForm: {
        taskSourceNo: "",
        taskNumber: "",
        corpId: "",
        corpName: "",
        itemIdList: [],
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      selectRows: [],
      refTable: "elTable",
      loading: false,
      queryListAPI: getItemListPage
    };
  },
  mixins: [base, listPage],
  computed: {},
  watch: {
    addOrderDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.initData();
          this.queryList();
        }
      }
    }
  },
  methods: {
    handleClear(val, node) {
      this.searchForm.corpName = node.corpName;
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        // this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },

    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selectRows = columns;
    },
    //确定选中
    confirm() {
      if (!this.selectRows.length) {
        this.$message.warning("请先勾选任务单");
        return;
      }
      if (this.projectId) {
        this.createProjectTaskRel();
      } else {
        console.log(this.selectRows, "~~~");
        this.$emit("bindOrder", this.selectRows);
        this.handleClose();
      }
    },
    //绑定任务单
    async createProjectTaskRel() {
      try {
        this.loading = true;
        let res = await createProjectTaskRel({
          projectId: this.projectId,
          itemIdList: this.selectRows.map(item => {
            return item.workOrderId;
          })
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.handleClose();
          this.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.searchForm = {
        taskSourceNo: "",
        taskNumber: "",
        corpId: "",
        corpName: "",
        itemIdList: [],
        pageIndex: 1,
        pageSize: 20
      };
      this.selectRows = [];
      this.$refs.elTable.clearSelection();
      this.$emit("handleClose", "addOrderDialog");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
