<!--
 * @Description: 添加人员
 * @Author: ChenXueLin
 * @Date: 2021-10-19 14:46:30
 * @LastEditTime: 2022-07-01 09:27:33
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="选择人员"
    :visible="selectPersonDialog"
    width="500px"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    :before-close="handleCloseSelectScene"
    custom-class="install-scene-dialog"
  >
    <el-form
      label-width="120px"
      ref="addUserForm"
      :model="addUserForm"
      :rules="addUserFormRules"
    >
      <el-form-item label="员工名称" prop="idList">
        <e6-vr-select
          v-model="addUserForm.idList"
          :data="userList"
          placeholder="员工名称"
          title="员工名称"
          :props="{
            id: 'employeeId',
            label: 'userName'
          }"
          clearable
          multiple
        ></e6-vr-select>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseSelectScene">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getListIdAndNames, createProjectEmpRel } from "@/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      dialogLoading: false,
      userList: [],
      addUserForm: {
        idList: []
      },
      addUserFormRules: {
        idList: [
          {
            required: true,
            message: "请选择人员",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["projectId", "selectPersonDialog"],
  mixins: [base],
  computed: {},
  watch: {
    selectPersonDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryList();
        }
      }
    }
  },
  created() {},
  methods: {
    //获取用户列表
    async queryList() {
      try {
        this.dialogLoading = true;
        let res = await getListIdAndNames({
          id: 1
        });
        this.userList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击确定
    confirm() {
      this.$refs.addUserForm.validate(valid => {
        if (valid) {
          this.createProjectEmpRel();
        }
      });
    },
    //添加员工请求
    async createProjectEmpRel() {
      try {
        this.dialogLoading = true;
        let res = await createProjectEmpRel({
          employeeIdList: this.addUserForm.idList,
          projectId: this.projectId
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.addUserForm.idList = [];
          this.$emit("refresh");
          this.handleCloseSelectScene();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleCloseSelectScene() {
      this.$emit("handleClose", "selectPersonDialog");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog {
  .el-dialog__body {
    padding-top: 20px;
  }
}
</style>
